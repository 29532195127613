import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
      <Helmet>
        <title>PrimeDrive Rentals</title>
        <meta
          name={"description"}
          content={
            "PrimeDrive Rentals ile birinci sınıf araç kiralama hizmetlerini keşfedin. İhtiyaçlarınıza uygun esnek kiralama seçenekleriyle konfor ve stil içinde seyahat edin."
          }
        />
        <meta property={"og:title"} content={"PrimeDrive Rentals"} />
        <meta
          property={"og:description"}
          content={
            "PrimeDrive Rentals ile birinci sınıf araç kiralama hizmetlerini keşfedin. İhtiyaçlarınıza uygun esnek kiralama seçenekleriyle konfor ve stil içinde seyahat edin."
          }
        />
        <meta
          property={"og:image"}
          content={"https://brai-kzflor.com/img/435852341.png"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://brai-kzflor.com/img/435852341.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://brai-kzflor.com/img/435852341.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://brai-kzflor.com/img/435852341.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://brai-kzflor.com/img/435852341.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://brai-kzflor.com/img/435852341.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://brai-kzflor.com/img/435852341.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="50px 0 80px 0" sm-padding="40px 0">
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="h1"
          font="--headline1"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Gizlilik Politikası
        </Text>
        <Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
          Bu Gizlilik Politikası ("Politika"), brai-kzflor.com ("biz", "bize" veya "bizim") web sitesi ziyaretçileri ve kullanıcılarının ("siz" veya "sizin") kişisel verilerini nasıl topladığını, kullandığını, aktardığını ve koruduğunu açıklar. ("Hizmetler").
          <br />
          <br />
          Hizmetlerimizi kullanarak, bu Politikada açıklanan uygulamaları kabul etmiş olursunuz. Bu Politikayı kabul etmiyorsanız, lütfen Hizmetlerimizi kullanmayın.
          <br />
          <br />
          1. Topladığımız Bilgiler
          <br />
          <br />
          Şu tür bilgileri toplayabiliriz:
          <br />
          <br />
          1.1 Kişisel veriler: İsim, e-posta adresi, posta adresi gibi sizin tarafınızdan gönüllü olarak Hizmetlerimizle etkileşim kurarken sağlanan kişisel verileri toplayabiliriz.
          <br />
          <br />
          1.2 Kullanım bilgileri: Hizmetlerimizle olan etkileşiminiz hakkında IP adresiniz, tarayıcı türünüz, işletim sisteminiz ve ziyaret edilen sayfalar gibi kişisel olmayan bilgileri toplayabiliriz. Ayrıca bu bilgileri toplamak için çerezler ve benzeri teknolojiler kullanabiliriz.
          <br />
          <br />
          2. Bilgilerinizi Nasıl Kullanıyoruz
          <br />
          <br />
          Bilgilerinizi şu amaçlarla kullanıyoruz:
          <br />
          <br />
          2.1 Hizmetlerin Sağlanması ve İyileştirilmesi: Bilgilerinizi Hizmetlerimizi sağlamak, yönetmek ve iyileştirmek için kullanıyoruz, taleplerinize ve isteklerinize yanıt vermek dahil.
          <br />
          <br />
          2.2 Analiz: Kullanıcı eğilimlerini ve tercihlerini analiz etmek için analitik araçlar kullanıyoruz, bu da Hizmetlerimizi iyileştirmemize yardımcı oluyor.
          <br />
          <br />
          3. Bilgilerinizi Paylaşma
          <br />
          <br />
          Kişisel bilgilerinizi üçüncü taraflara satmıyor, kiralamıyor veya takas etmiyoruz. Ancak, bilgilerinizi şu durumlarda paylaşabiliriz:
          <br />
          <br />
          3.1 Yasal Gereklilikler: Yasalar, düzenlemeler, mahkeme süreçleri veya hükümet talepleri gerektiriyorsa bilgilerinizi açıklayabiliriz.
          <br />
          <br />
          3.2 Hakların Korunması: Haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizi korumak veya herhangi bir kişiye yönelik bir acil duruma yanıt vermek gerektiğine inandığımızda bilgilerinizi açıklayabiliriz.
          <br />
          <br />
          4. Seçenekleriniz
          <br />
          <br />
          Kişisel bilgilerinizle ilgili şu seçeneklere sahipsiniz:
          <br />
          <br />
          4.1 Erişim ve Düzeltme: Kişisel bilgilerinize erişebilir ve bunları düzeltmek için bizimle contact@brai-kzflor.com adresinden iletişime geçebilirsiniz.
          <br />
          <br />
          4.2 Reddetme: Bizden reklam e-postaları almayı reddedebilirsiniz, bu e-postalardaki talimatları takip ederek.
          <br />
          <br />
          5. Güvenlik
          <br />
          <br />
          Bilgilerinizi korumak için makul idari, teknik ve fiziksel önlemler kullanıyoruz. Ancak, İnternet üzerinden iletim veya elektronik depolama yöntemi tamamen güvenli değildir ve mutlak güvenlik garanti edemeyiz.
          <br />
          <br />
          6. Çocukların Gizliliği
          <br />
          <br />
          Hizmetlerimiz 13 yaşın altındaki kişiler için tasarlanmamıştır. 13 yaşın altındaki bir çocuğun bilgilerini kazara topladığımıza inanıyorsanız, bizimle iletişime geçin, bu bilgileri hemen sileceğiz.
          <br />
          <br />
          7. Gizlilik Politikasında Değişiklikler
          <br />
          <br />
          Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Veri işleme uygulamalarımız hakkında bilgi sahibi olmanız için lütfen bu Politikayı periyodik olarak gözden geçirin.
          <br />
          <br />
          8. Bizimle İletişime Geçin
          <br />
          <br />
          Bu Gizlilik Politikası veya veri işleme uygulamalarımız hakkında herhangi bir sorunuz, endişeniz veya isteğiniz varsa, bizimle manager@brai-kzflor.com adresinden iletişime geçin.
        </Text>
      </Section>
      <Components.Footer13 />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
}